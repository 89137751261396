import { createStore } from 'vuex'
import user from './moudels/user'
import app from './moudels/app'
import getters from './getters'

export default createStore({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user
  }
})
