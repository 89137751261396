import { TIME_STAMP, TOKEN_TIMEOUT } from '@/constants'
import { getItem, setItem } from './stroage'

// 获取时间戳
export function getTimeStamp () {
  return getItem(TIME_STAMP)
}

// 设置时间戳
export function setTimeStamp (value) {
  setItem(TIME_STAMP, Date.now())
}

// 是否过期
export function isTimeOut () {
  const nowTime = Date.now()
  const oldTime = getTimeStamp()
  return nowTime - oldTime > TOKEN_TIMEOUT
}
