import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/views/layout/index'

const privateRoutes = [
  {
    path: '/user',
    component: layout,
    redirect: '/user/manage',
    meta: {
      title: '用户管理',
      icon: 'user'
    },
    children: [
      {
        path: '/user/list',
        component: () => import('@/views/users/index'),
        meta: {
          title: '用户列表',
          icon: 'personnel-manage'
        }
      }
      // {
      //   path: '/user/manage',
      //   component: () => import('@/views/user-manage/index'),
      //   meta: {
      //     title: 'userManage',
      //     icon: 'personnel-manage'
      //   }
      // },
      // {
      //   path: '/user/role',
      //   component: () => import('@/views/role-list/index'),
      //   meta: {
      //     title: 'roleList',
      //     icon: 'role'
      //   }
      // },
      // {
      //   path: '/user/permission',
      //   component: () => import('@/views/permission-list/index'),
      //   meta: {
      //     title: 'permissionList',
      //     icon: 'permission'
      //   }
      // },
      // {
      //   path: '/user/info/:id',
      //   name: 'userInfo',
      //   component: () => import('@/views/user-info/index'),
      //   meta: {
      //     title: 'userInfo'
      //   }
      // },
      // {
      //   path: '/user/import',
      //   name: 'import',
      //   component: () => import('@/views/import/index'),
      //   meta: {
      //     title: 'excelImport'
      //   }
      // }
    ]
  },
  {
    path: '/article',
    component: layout,
    redirect: '/article/ranking',
    meta: {
      title: '文章管理',
      icon: 'tickets'
    },
    children: [
      {
        path: '/article/ranking',
        component: () => import('@/views/article-ranking/index'),
        meta: {
          title: '文章列表',
          icon: 'article-ranking'
        }
      },
      {
        path: '/article/:id',
        component: () => import('@/views/article-detail/index'),
        meta: {
          title: '文章详情'
        }
      },
      {
        path: '/article/create',
        component: () => import('@/views/article-create/index'),
        meta: {
          title: '创建文章'
        }
      },
      {
        path: '/article/editor/:id',
        component: () => import('@/views/article-create/index'),
        meta: {
          title: '编辑文章'
        }
      }
    ]
  },
  {
    path: '/video',
    component: layout,
    redirect: '/article/ranking',
    meta: {
      title: '视频管理',
      icon: 'tickets'
    },
    children: [
      {
        path: '/video/index',
        component: () => import('@/views/video/index'),
        meta: {
          title: '视频列表',
          icon: 'article-ranking'
        }
      },
      {
        path: '/video/:id',
        component: () => import('@/views/article-detail/index'),
        meta: {
          title: '视频详情'
        }
      },
      {
        path: '/video/create',
        component: () => import('@/views/video-create/index'),
        meta: {
          title: '创建视频'
        }
      },
      {
        path: '/video/editor/:id',
        component: () => import('@/views/video-create/index'),
        meta: {
          title: '编辑视频'
        }
      }
    ]
  }
]

const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    component: layout,
    redirect: '/article',
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/index'),
        meta: {
          title: 'profile',
          icon: 'el-icon-user'
        }
      },
      {
        path: '/404',
        name: '404',
        component: import('@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: import('@/views/error-page/401')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...privateRoutes, ...publicRoutes]
})

export default router
