import router from './router'
import store from './store'

const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  if (store.getters.token) {
    if (to.path === '/login') {
      next('/')
    } else {
      // 判断用户信息是否存在  不存在 获取用户信息
      if (!store.getters.hasUserInfo) {
        store.dispatch('user/getUserInfo')
      }
      next()
    }
  } else {
    // 没有token 说明没有登录 只能去不需要登录的页面
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
