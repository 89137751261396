<template>
  <!-- 一级菜单 -->
  <el-menu
    router
    :collapse="!$store.getters.sidebarOpened"
    :uniqueOpened="true"
    :default-active="activeMenu"
    :background-color="$store.getters.cssVar.menuBg"
    :text-color="$store.getters.cssVar.menuText"
    :active-text-color="$store.getters.menuActiveText"
  >
    <!-- 子集菜单 -->
    <div v-for="route in routes" :key="route.path">
      <el-submenu v-if="route.children.length > 0" :index="route.path">
        <template #title>
          <i :class="'el-icon-' + route.meta.icon"></i>
          <span>{{ route.meta.title }}</span>
        </template>
        <div v-for="item in route.children" :key="item.path" :route="item">
          <el-menu-item :index="item.path">{{ item.meta.title }}</el-menu-item>
        </div>
      </el-submenu>
      <el-menu-item v-else :index="route.path">
        <menu-item :title="route.meta.title" icon="el-icon-user"></menu-item>
      </el-menu-item>
    </div>
  </el-menu>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router'
import { computed } from 'vue'
import { filterRouters, generateMenus } from '@/utils/route'

const router = useRouter()
console.log(router.getRoutes())
const routes = computed(() => {
  const filterAllRoutes = filterRouters(router.getRoutes())
  return generateMenus(filterAllRoutes)
})
console.log(JSON.stringify(routes.value))

// 计算高亮
const route = useRoute()
const activeMenu = computed(() => {
  const { path } = route
  return path
})
</script>
