import { login, getUserInfo } from '@/api/sys'
import router from '@/router'
import { TOKEN } from '@/constants'
import { getItem, removeAllItem, setItem } from '@/utils/stroage'
import md5 from 'md5'
import { setTimeStamp } from '@/utils/auth'
export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {}
  }),
  mutations: {
    setToken (state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    login (context, userInfo) {
      // 处理密码 md5加密
      const { username, password } = userInfo
      console.log('password---', password)
      return new Promise((resolve, reject) => {
        login({ username: username, password: md5(password) }).then(res => {
          this.commit('user/setToken', res.token)
          setTimeStamp()
          resolve()
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    async getUserInfo (context) {
      const res = await getUserInfo()
      this.commit('user/setUserInfo', res)
      return res
    },
    logout (context) {
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      removeAllItem()
      // 清除权限相关的
      router.push('/login')
    }
  }

}
